.footerArea{
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 16px;
}

#pageFooter{
    border-radius: 1px;
    border: 1px dashed rgba(242, 242, 242, .2);
    background-color: rgba(94, 92, 92, .2);
}

.ftWidget{
    padding: 40px 20px;
    background-color: rgba(0, 0, 0, .2);
    border-right: 1px dashed rgba(242, 242, 242, .2);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ftLogo{
    margin-bottom: 70px;
    max-width: 235px;
}

.ftLogo p{
    margin-top: 17px;
    color: #FFF;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
}

.cpyTxt{
    font-weight: 100;
    line-height: 28px;
}

.cpyTxt a:hover{
    color: var(--clrTheme);
}

.cpyTxt span{
    display: block;
    width: 100%;
}

.ftRow{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.ftRow .ftCol{
    padding-left: 15px;
    padding-right: 15px;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    display: flex;
}

.ftNav{
    padding: 50px 20px 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.ftList{
    margin-bottom: 30px;
    width: 100%;
    flex: 0 0 auto;
}

.ftList > li + li{
    margin-top: 7px;
}

.ftList > li{
    position: relative;
    padding-left: 40px;
}

.ftList > li > a{
    color: var(--white);
}

.ftList > li > a:before{
    position: absolute;
    content: "\e900";
    top: 10px;
    left: -3px;
    font-size: 10px;
    font-family: 'omelatte-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #727272;
    transition: color .35s ease, left .35s ease;
}

.ftList > li > a:hover:before,
.ftList > li > a.active:before{
    color: var(--clrTheme);
    left: 0;
}

@media (min-width: 576px){
    .ftWidget,
    .ftNav{
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 768px){
    .ftNav{
        column-gap: 30px;
    }

    .ftList{
        width: calc(50% - 15px);
    }

    .ftList > li + li{
        margin-top: 10px;
    }
}

@media (min-width: 992px){
    .ftNav{
        column-gap: 20px;
    }

    /* .ftList{
        width: calc(40% - 15px);
    } */

    /* .ftNav > ul:not(:first-child){
        width: calc(30% - 15px);
    } */


    .ftRow .ftCol.ftCol35{
        flex: 0 0 auto;
        width: 35%;
    }

    .ftRow .ftCol.ftCol65{
        flex: 0 0 auto;
        width: 65%;
    }

    .ftNav{
        padding-left: 10px;
        padding-right: 0;
    }
}

@media (min-width: 1200px){
}

@media (min-width: 1440px){
    .ftWidget{
        padding: 58px 65px;
    }

    .ftNav{
        padding: 58px 30px 58px;
    }
}

@media (min-width: 1550px){
    .ftNav{
        padding: 58px 30px 58px 70px;
        /* column-gap: 30px; */
    }
}