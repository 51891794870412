/* Icons_Fonts */
@font-face{
    font-family: 'omelatte-icons';
    src:  url('../fonts/omelatte-icons/omelatte-icons.eot');
    src:  url('../fonts/omelatte-icons/omelatte-icons.eot') format('embedded-opentype'),
        url('../fonts/omelatte-icons/omelatte-icons.ttf') format('truetype'),
        url('../fonts/omelatte-icons/omelatte-icons.woff') format('woff'),
        url('../fonts/omelatte-icons/omelatte-icons.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
  
[class^="icn-"], [class*=" icn-"]{
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'omelatte-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icn-arrow-large:before {
    content: "\e900";
}
  
.icn-arrow-up-right-v1:before {
    content: "\e901";
}
  
.icn-arrow-up-right:before {
    content: "\e902";
}
  
.icn-ellipse:before {
    content: "\ea56";
}

.icn-polygon:before{
    content: "\e904";
}

/* Icons_Fonts */