html{
    scroll-behavior: smooth;
    -ms-overlfow-style: scrollbar;
}

body{
    margin: 0;
    padding: 0;
}

:root {
    --clrTheme: #FBB112;
    --themeBlack: #211E1D;
    --themeWhite: #EFEFEF;
    --clrYellow: #F9BA21;
    --clrGreen: #79D88C;
    --clrOrange: #FE6605;
    --white: #ffffff;
    --black: #000000;
    --grey100: rgba(239, 239, 239, 0.6);
    --grey200: #F2F2F2;
    --grey300: #4B4B4B;
    --grey400: #5E5C5C;
    --grey500: #2A2727;
    --grey600: #677280;
    --grey700: #322F2E;
    --grey800: #D9D9D9;
}

#pageWrapper{
    min-width: 320px;
    font-family: 'Archivo', sans-serif;
    color: var(--themeWhite);
    background-color: var(--themeBlack);
    line-height: 30px;
    scroll-behavior: smooth;
    -ms-overlfow-style: scrollbar;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* overflow: hidden; */
}

.txtCenter{
    text-align: center;
}

.txtUppercase{
    text-transform: uppercase;
}

.clrTheme{
    color: var(--clrTheme);
}

.fontPoppins{
    font-family: 'Poppins', sans-serif;
}

.imgFluid{
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.dBlock{
    display: block;
}

.formControl{
    border-radius: 1px;
    display: block;
    width: 100%;
    height: 62px;
    padding: 16px;
    color: var(--white);
    background-color: var(--grey500);
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    border: 1px solid transparent;
    transition: border-color 0.45s ease;
}

.formControl:focus,
.formControl:focus-visible{
    border-color: rgba(239, 239, 239, .25);
}

textarea.formControl{
    min-height: 250px;
}

.formControl::placeholder{
    font-family: inherit;
    color: var(--grey100);
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
}

.formControl::-moz-placeholder{
    font-family: inherit;
    color: var(--grey100);
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
}

.formControl::-webkit-input-placeholder{
    font-family: inherit;
    color: var(--grey100);
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
}

/* Chrome, Safari, Edge, Opera */
.formControl[type="number"]::-webkit-outer-spin-button,
.formControl[type="number"]::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.formControl[type=number] {
    -moz-appearance: textfield;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6{
    margin-top: 0;
    font-weight: 900;
    letter-spacing: -2.4px;
    font-family: 'Archivo', sans-serif;
    color: var(--themeWhite);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a{
    color: inherit;
}

h1, .h1{
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
}

h2, .h2{
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
}

.txtOutline{
    color: #252220;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px var(--themeWhite);
}

/* .txtOutline .icn{
    transition: transform .35s ease;
}

.h1:hover .txtOutline .icn{
    transform: rotate(45deg);
} */

.btn{
    font-weight: 400;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    transition: all .35s ease;
    padding: 10px 30px;
    min-width: 150px;
}

.btnPrimary{
    color: var(--white);
    background-color: var(--clrTheme);
    border-color: var(--clrTheme);
}

a{
    color: inherit;
    text-decoration: none;
    transition: all .35s ease;
}

.container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.popupOverlay{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 99;
    position: fixed;
    visibility: hidden;
    transition: all ease .3s;
    background-color: #00000080;
}

.mobile-menu-open .popupOverlay {
    opacity: 1;
    visibility: visible;
}

.sectionHeader{
    margin-bottom: 50px;
}

.sectionHeader p{
    color: var(--grey100)
}

/*__ Sechedule Discovery Modal __*/
.scheCallModal{
    height: 100%;
}

body.ReactModal__Body--open{
    overflow: hidden;
}

.ReactModal__Overlay{
    position: relative;
    padding: 30px 15px;
}

.ReactModal__Content{
    padding: 0 !important;
    border: 0 !important;
}

.ReactModal__Overlay,
.ReactModal__Content{
    background-color: rgba(16, 16, 16, .85) !important;
    z-index: 99999;
}

.scheCallModal iframe{
    height: inherit;
}

.scheCallModal .clsBtn{
    color: var(--grey600);
    position: absolute;
    top: 40px;
    right: 30px;
    font-size: 20px;
    cursor: pointer;
    transition: color 0.35s ease;
}

.scheCallModal .clsBtn.undefined{
    color: var(--grey600);
}

.scheCallModal .clsBtn.dark{
    color: var(--themeWhite);
}

.scheCallModal .clsBtn.light{
    color: var(--themeBlack);
}

.scheCallModal .clsBtn:hover{
    color: var(--clrTheme);
}

/*__ Fancy Box __*/
.fancybox__footer .f-thumbs__slide > button{
    border-radius: 12px;
    overflow: hidden;
}

.fancybox__footer .f-thumbs__slide.is-selected > button{
    border-radius: 4px;
}

.fancybox__footer .f-thumbs__slide > button img{
    border-radius: inherit;
}

/***************************** 
    Banner_Section 
*****************************/
.bannerSection{
    padding-top: 50px;
    padding-bottom: 80px;
    color: rgba(239, 239, 239, .6)
}

.bannerSection .sectionHeader{
    max-width: 540px;
}

.bnrWidget{
    position: relative;
    max-width: 387px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.bnrWidget .flotingObject{
    position: absolute;
    left: -31px;
    bottom: -42px;
    max-width: 185px;
    width: 100%;
    pointer-events: none;
}

.bnrWidget .wdgHolder{
    text-align: center;
    position: relative;
    padding: 43px 20px 30px;
    border-radius: 24px;
    background: linear-gradient(149deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.10) 100%);
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(5px);
    z-index: 1;
    overflow: hidden;
}

.bnrWidget .wdgHolder:before,
.bnrWidget .wdgHolder:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    border: 3px solid transparent;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    transition: all .35s linear;
    transform-origin: 0 0;
}

.bnrWidget .wdgHolder:before{
    background: linear-gradient(139deg, rgba(255,255,255,0.7) 0%, rgba(126,126,126,0.4) 33.333%, rgba(84,84,84,0.4) 66.666%, rgba(79,79,79,0.6) 100%) border-box;
}

.bnrWidget .wdgHolder:after{
    transform: scale(1.015);
    opacity: 0;
    visibility: hidden;
    background: linear-gradient(-45deg, rgba(255,255,255,0.7) 0%, rgba(126,126,126,0.4) 33.333%, rgba(84,84,84,0.4) 66.666%, rgba(79,79,79,0.6) 100%) border-box;
}

.bnrWidget:hover .wdgHolder:after{
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}

.bnrWidget:hover .wdgHolder:before{
    opacity: 0;
    visibility: hidden;
    transform: scale(1.015);
}

.bnrWidget .wdgHolder > span{
    display: inline-block;
}

.bnrWidget .wdgTag{
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.72px;
    color: var(--black);
    padding: 10px 25px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #5E5E5E;
    border-radius: 100px;
    margin-top: 12px;
    margin-bottom: 18px;
    position: relative;
    transition: transform 0.35s ease;
    cursor: default;
    z-index: 1;
}

.bnrWidget .wdgTag:hover{
    transform: scale(1.03);
}

.bnrWidget .wdgTag .flotingIcn{
    position: absolute;
    left: -15px;
    bottom: -10px;
}

.bnrWidget .wdgTag.bgYellow{
    background-color: var(--clrYellow);
}

.bnrWidget .wdgTag.bgGreen{
    background-color: var(--clrGreen);
    padding-left: 41px;
}

.bnrWidget .wdgTag.bgOrange{
    background-color: var(--clrOrange);
}

.bnrWidget .gradLne{
    width: 100%;
    height: 14px;
    display: inline-block;
    opacity: 0.3;
    width: calc(100% - 14px);
    background: linear-gradient(90deg, #EDEDED 0%, rgba(237, 237, 237, 0.00) 100%);;
}

.bannerSection .omlRow{
    column-gap: 30px;
}

.bannerSection .txtOutline{
    display: block;
    width: 100%;
}

.bannerSection .txtOutline .icn{
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-color: var(--clrTheme);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    color: #252220;
    -webkit-text-fill-color: var(--themeBlack);
    -webkit-text-stroke: 0px;
    font-size: 15px;
    vertical-align: middle;
}

.bannerSection .txtOutline a:hover{
    background-color: var(--clrYellow);
}

.bannerSection .txtTag{
    margin-bottom: 15px;
}

.bannerSection .h1{
    margin-bottom: 5px;
}

/***************************** 
    Call_Book_Widget 
*****************************/
.callBookAside{
    padding-top: 25px;
    padding-bottom: 25px;
    border-color: rgba(242, 242, 242, .23);
    border-width: 1px 0;
    border-style: solid;
}

.estCstBtn{
    border-radius: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
    margin: 0 auto;
    transition: box-shadow 0.35s ease;
    padding-top: 20px;
    background: linear-gradient(149deg, #3E3B3A 0%, #3E3B3A 100%);
    text-align: center;
}

.estCstBtn .planTxt{
    padding: 15px 19px 16px 24px;
}

.estCstBtn .lgoWrap{
    border-radius: inherit;
    background-color: var(--clrTheme);
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(5px);
    padding: 15px 23px;
    max-width: 206px;
    position: relative;
    overflow: hidden;
}

.estCstBtn .lgoWrap:before,
.estCstBtn .lgoWrap:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100px;
    border: 3px solid transparent;
    background: linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(126,126,126,0.4) 33.333%, rgba(84,84,84,0.4) 66.666%, rgba(79,79,79,0.6) 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    transition: all .35s linear;
    transform-origin: 0 0;
}

.estCstBtn .lgoWrap:before{
    background: linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(126,126,126,0.4) 33.333%, rgba(84,84,84,0.4) 66.666%, rgba(79,79,79,0.6) 100%) border-box;
}

.estCstBtn .lgoWrap:after{
    opacity: 0;
    visibility: hidden;
    transform: scale(1.025);
    background: linear-gradient(-94deg, rgba(255,255,255,0.7) 0%, rgba(126,126,126,0.4) 33.333%, rgba(84,84,84,0.4) 66.666%, rgba(79,79,79,0.6) 100%) border-box;
}

.estCstBtn:hover .lgoWrap:before{
    opacity: 0;
    visibility: hidden;
    transform: scale(1.025);
}

.estCstBtn:hover .lgoWrap:after{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.estCstBtn .lgoWrap img{
    vertical-align: middle;
    max-width: 160px;
}

.estCstBtn:hover {
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.20);
}

/*****************************
    Secvices_Section 
******************************/
.secvicesSection{
    padding-bottom: 180px;
    position: relative;
}

.secvicesSection .bgImg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: calc(100% + 73px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
}

.secvicesSection .container{
    position: relative;
    z-index: 2;
}

.secvicesSection .sectionHeader{
    padding-top: 40px;
    margin-bottom: 50px;
    position: relative;
}

.secvicesSection .sectionHeader .scrollRef{
    position: absolute;
    top: 0;
    top: -79px;
}

.secvicesSection .imgWrap{
    border-radius: 8px;
    overflow: hidden;
}

.secvicesSection .imgWrap img{
    border-radius: inherit;
    vertical-align: top;
}

.servcList{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.servcList > li{
    padding-left: 15px;
    padding-right: 15px;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid transparent;
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.servcList > li:before{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: url('../images/img-waves.png') repeat 50% 50%;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 0;
    transition: opacity .35s ease;
    -webkit-animation: scroll 30s infinite;
    -moz-animation: scroll 30s infinite;
    -o-animation: scroll 30s infinite;
    animation: scroll 30s infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes scroll {
    0% {
        background-position: 0% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.servcList > li:hover:before{
    opacity: .05;
}

.srvcWidget{
    padding: 30px 40px;
    color: rgba(239, 239, 239, .6);
    font-weight: 300;
    position: relative;
    z-index: 2;
    border-bottom: 1px solid rgba(242, 242, 242, .13);
    width: 100%;
}

.srvcWidget .srvTitle{
    font-size: 22px;
    line-height: 25px;
    font-weight: 400;
    margin-bottom: 10px;
    display: inline-block;
    letter-spacing: -0.66px;
    color: #F5F5F5;
    position: relative;
}

.srvcWidget .srvTitle:before{
    position: absolute;
    content: "\ea56";
    top: 5px;
    left: -32px;
    font-size: 16px;
    font-family: 'omelatte-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #727272;
    transition: color .35s ease;
}

.srvcWidget .readMoreLnk{
    display: inline-flex;
    align-items: center;
    margin-top: 35px;
}

.srvcWidget .readMoreLnk .icn{
    width: 37px;
    height: 37px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    font-size: 10px;
    color: var(--grey200);
    transition: background-color 0.35s ease, color 0.35s ease, transform .35s ease, -webkit-transform .35s ease;
}

.servcList > li:hover .readMoreLnk .icn,
.srvcWidget .readMoreLnk:hover .icn{
    background-color: var(--clrTheme);
    color: var(--themeBlack);
}

.srvcWidget .readMoreLnk:hover .icn{
    transform: rotate(45deg);
}

.servcList li:nth-child(even) .srvcWidget .srvTitle:before{
    content: "\e904";
    font-weight: 900;
}

/***************************** 
    Get_Your_App_Section 
*****************************/
.gtYrAppSection{
    padding-bottom: 50px;
    position: relative;
}

.gtYrAppSection .bgSec{
    position: absolute;
    top: 57px;
    left: 0;
    right: 0;
    /* right: 8px; */
    width: 100%;
    height: 200px;
    /* transform: rotate(4deg); */
    background-color: #252220;
    background-color: var(--themeBlack);
}

.gtYrAppSection .bgSec:before{
    position: absolute;
    content: '';
    top: -50px;
    right: 0;
    width: 100%;
    height: 50px;
    background-color: #252220;
}

.gtYrAppSection .container{
    position: relative;
    z-index: 1;
}

.gtYrAppSection .sctnTagElem{
    margin-top: -80px;
    transform: rotate(-3.131deg);
    margin-bottom: 50px;
    text-align: center;
    transition: transform 0.35s ease;
}

.gtYrAppSection .sctnTagElem span{
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px;
}

.gtYrAppSection .sctnTagElem .txtTag{
    transform: none;
    padding: 17px 24px;
    letter-spacing: -1.05px;
    cursor: pointer;
    transition: background-color 0.35s ease;
}

.gtYrAppSection .sctnTagElem .txtTag:hover{
    background-color: var(--clrYellow);
}

.gtYrAppSection:hover .sctnTagElem{
    transform: none;
}

.gtYrAppSection .omlRow{
    column-gap: 50px;
}

.appFtrList li + li{
    margin-top: 38px;
}

.appFtrWdgt{
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    color: rgba(245, 245, 245, .6);
}

.appFtrWdgt .imgWrap{
    min-width: 130px;
    margin-bottom: 35px;
}

.appFtrWdgt .titleTxt{
    color: var(--white);
    letter-spacing: -0.9px;
    margin-bottom: 18px;
    display: block;
    width: 100%;
    font-size: 25px;
    line-height: 30px;
    font-weight: 500;
    position: relative;
    max-width: 290px;
}

.appFtrWdgt .titleTxt:before{
    position: absolute;
    content: "";
    left: 0;
    top: -15px;
    width: 35%;
    height: 1px;
    background-color: var(--clrYellow);
}

/***************************** 
    Blog_Post_Section 
*****************************/
.blogSection{
    padding-bottom: 20px;
    overflow: hidden;
    position: relative;
}

.blogSection .container{
    position: relative;
    z-index: 2;
}

.blogSection .bgImg{
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    height: calc(73% - 200px);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

.blogSection .flotScrlRef{
    position: absolute;
    top: -81px;
    left: 0;
}

.blogSection .sectionHeader{
    border-top: 1px solid rgba(242, 242, 242, .23);
    margin-bottom: 0;
    margin-bottom: 70px;
}

.blogSection .sectionHeader .h1{
    margin-bottom: 0;
}

.blogSection .h1 .txtOutline{
    display: block;
    width: 100%;
}

.blogSection .txtTag{
    transform: none;
    margin-bottom: 10px;
    margin-top: -1px;
}

.bgSliderWrap{
    max-width: 894px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.blogSection .slick-slider{
    padding-top: 20px;
}

.blogSection .slick-slider .slick-list{
    overflow: visible;
}

.blogPost{
    color: rgba(255, 255, 255, .6);
    position: relative;
    margin-bottom: 40px;
}

.blogPost[data-feature="Latest"]:before{
    position: absolute;
    content: '';
    top: -35px;
    left: -17px;
    width: 90px;
    height: 90px;
    background-image: url('../images/ico05.svg');
    background-size: cover;
    background-position: center;
    z-index: 1;
    pointer-events: none;
}

.blogPost .descpWrap{
    transition: opacity .5s ease;
}

.blogPost .descpWrap .h1{
    font-size: 30px;
    line-height : 39px;
    letter-spacing: -1.5px;
}

.blogPost .descpWrap .h1 a:hover{
    color: var(--clrTheme);
}

.blogSection .slick-slider .blogPost{
    padding-left: 5px;
    padding-right: 5px;
}

.blogSection .slick-slider .slick-slide:not(.slick-current) .blogPost .descpWrap{
    opacity: 0;
    visibility: hidden;
}

.blogSection .slick-slider .slick-slide:not(.slick-current) .blogPost .imgWrap{
    opacity: .35;
    background-color: var(--grey400);
}

.blogSection .bgsRow{
    column-gap: 147px;
}

.blogPost .imgWrap{
    border-radius: 1px;
    overflow: hidden;
    margin-bottom: 15px;
    transition: opacity .5s ease;
    background-color: var(--grey700);
    padding: 10px;
}

.blogPost .imgWrap img{
    border-radius: inherit;
    vertical-align: top;
    transition: transform .95s linear;
    width: 100%;
}

.blogPost:hover .imgWrap img{
    /* transform: scale(1.15); */
}

.blogPost .infoWrap{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 8px 20px;
    margin-bottom: 15px;
}

.blogPost .blgCat{
    opacity: .6;
    font-weight: 100;
    font-size: 14px;
}

.tagList{
    display: flex;
    align-items: center;
    gap: 10px 16px;
}

.tagList > li > a{
    color: var(--clrYellow);
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    padding: 6px 13px;
    background-color: rgba(249, 186, 33, .3);
}

.tagList > li.active > a,
.tagList > li > a:hover{
    color: var(--themeBlack);
    background-color: var(--clrTheme);
}

.blogPost .subTlte{
    font-size: 18px;
    line-height: 23px;
    font-weight: 500;
    letter-spacing: -0.6px;
    color: var(--white);
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 22px;
}

.ftrList{
    font-size: 16px;
    color: var(--white);
    margin-top: 16px;
}

.ftrList > li{
    position: relative;
    padding-left: 34px;
}

.ftrList > li:before{
    position: absolute;
    content: "\e900";
    top: 14px;
    left: 0;
    font-size: 8px;
    font-family: 'omelatte-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--clrTheme);
}

/***************************** 
    How_It_Work_Section 
*****************************/
.hwItWrkSection{
    padding-bottom: 50px;
}

.hiwList{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    counter-reset: item;
    justify-content: center;
}

.hiwList li{
    flex-shrink: 0;
    display: flex;
    width: 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
}

/* .hiwList li + li:before{
    bottom: 55px;
    left: -20px;
    transform: rotate(7deg);
    font-size: 28px;
}

.hiwList li + li:nth-child(odd):before{
    bottom: auto;
    top: 30px;
    transform: rotate(75deg);
} */

.hiwWidget{
    border-radius: 140px;
    background-color: var(--grey500);
    padding-top: 45px;
    padding-bottom: 85px;
    overflow: hidden;
    margin: 0 auto 20px;
    max-width: 290px;
    width: 100%;
}

.hiwWidget a{
    text-decoration: underline;
}

.hiwWidget a:hover{
    color: var(--clrTheme);
}

.hiwWidget .hiwCounter{
    margin: auto 15px 34px;
    display: inline-block;
}

.hiwWidget .hiwCounter:before{
    counter-increment: item;
    content: counter(item);
    width: 58px;
    height: 58px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    line-height: 30px;
    font-weight: 500;
    color: var(--themeBlack);
    background-color: var(--clrTheme);
}

.hiwWidget .hiwTitle{
    transform: rotate(-4.369deg);
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: -0.66px;
    color: var(--white);
    padding: 16px 15px;
    margin-bottom: 24px;
    transition: color .35s ease;
    position: relative;
    transition: transform .35s ease;
}

.hiwWidget .hiwTitle:before{
    position: absolute;
    content: "";
    top: 0;
    left: -10px;
    right: -10px;
    width: calc(100% + 20px);
    height: 100%;
    z-index: -1;
    background-color: var(--grey300);
    transition: background-color .35s ease;
}

.hiwList li:nth-child(even) .hiwTitle{
    transform: rotate(4.369deg);
}

.hiwWidget p{
    padding-left: 25px;
    padding-right: 25px;
    line-height: 28px;
    font-family: 'Poppins', sans-serif;
    color: var(--grey100)
}

.hiwWidget:hover .hiwTitle{
    color: var(--themeBlack);
    transform: rotate(0);
}

.hiwList li:nth-child(even) .hiwWidget:hover .hiwTitle{
    transform: rotate(0);
}

.hiwWidget:hover .hiwTitle:before{
    background-color: var(--clrTheme);
}

/***************************** 
    Contact_Form_Section 
*****************************/
.contactSection{
    padding-bottom: 30px;
}

.contactSection .sectionHeader{
    margin-bottom: 30px;
}

.contactSection .sectionHeader .txtOutline{
    position: relative;
    margin-bottom: 12px;
}

.contactSection .sectionHeader .h1 .txtTag{
    position: absolute;
    left: 6px;
    bottom: -16px;
    font-size: 16px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.txtTag{
    display: inline-block;
    background-color: var(--clrTheme);
    color: var(--themeBlack);
    font-size: 19px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: -0.96px;
    padding: 10px;
    transform: rotate(-3.131deg);
    -webkit-text-fill-color: var(--themeBlack);
    -webkit-text-stroke: 0px;
    transition: transform 0.35s ease;
}

.contactWidget{
    background-color: rgba(0, 0, 0, .2);
    border-radius: 1px;
    padding: 30px 20px;
    color: var(--grey100);
}

.contactWidget .circle{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: inline-block;
    background-color: var(--clrTheme);
    margin-bottom: 25px;
}

.contactWidget .h2{
    line-height: 39px;
    letter-spacing: -1.08px;
    font-weight: 500;
    margin-bottom: 20px;
}

.contactWidget .schDisLink{
    font-weight: 600;
    display: inline-block;
    font-size: 16px;
    margin-top: 49px;
    color: var(--clrTheme);
    cursor: pointer;
}

.contactWidget .schDisLink:hover{
    text-decoration: underline;
}

.contactSection .contactForm{
    margin-bottom: 20px;
    padding-top: 50px;
}

.contactForm .omlRow{
    margin-left: -8px;
    margin-right: -8px;
}

.contactForm .omlCol{
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 15px;
}

.contactSection .emlTxt{
    display: block;
    font-size: 16px;
    /* margin-bottom: 15px; */
    margin-bottom: 5px;
    color: var(--clrTheme);
}

.contactSection .emailLink{
    font-size: 25px;
    line-height: 33px;
    font-weight: 500;
    word-wrap: break-word;
}

.emailLink:hover,
.contactUsSection .emailLink:hover{
    color: var(--clrTheme);
}

.contactSection .estCstBtn{
    margin-left: 0;
    margin-bottom: 57px;
    margin-bottom: 47px;
    background: transparent;
}

.contactSection .estCstBtn .planTxt{
    max-width: 260px;
    text-align: center;
}

.noOutline{
    color: var(--themeBlack);
    -webkit-text-fill-color: var(--themeBlack);
    -webkit-text-stroke: 0;
}

/*****************************
    About_Us_Page 
******************************/
.aboutUsSection{
    padding-top: 50px;
    padding-bottom: 60px;
}

.aboutUsSection .sectionHeader .h1{
    margin-bottom: 30px;
}

.aboutUsSection .sectionHeader strong{
    margin-bottom: 15px;
}

.wdpsList{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    gap: 13px 8px;
    margin-bottom: 60px;
}

.wdpsList > li{
    background-color: rgba(217, 217, 217, .03);
    color: var(--themeWhite);
    padding: 14px 16px;
    position: relative;
    transition: background-color .35s ease, color .35s ease;
}

.wdpsList > li:hover{
    background-color: var(--clrTheme);
    color: var(--themeBlack);
}

.tmList{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tmList > li{
    flex: 0 0 auto;
    width: 100%;
}

.teamWidget{
    margin-bottom: 40px;
}

.teamWidget .imgWrap{
    position: relative;
    max-width: 152px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    margin-bottom: 20px;
    /* background-color: #fefefe; */
}

.teamWidget .imgWrap img{
    border-radius: inherit;
    position: relative;
    z-index: 1;
}

.teamWidget .imgWrap .icn{
    position: absolute;
    top: 3px;
    right: 3px;
    font-size: 17px;
    transform: rotate(-45deg);
    color: var(--clrTheme);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
}

.teamWidget .h1{
    color: var(--clrTheme);
    font-size: 22px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: .5px;
    margin-bottom: 5px;
}

.teamWidget .desigTitle{
    text-transform: capitalize;
    display: block;
    width: 100%;
    margin-top: 10px;
    color: var(--themeWhite);
    font-size: 16px;
    font-weight: 300;
    line-height: 28px; 
}

.teamSection .detailWidget{
    color: var(--grey100);
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

.teamSection .detailWidget .h1{
    font-size: 40px;
    font-weight: 500;
    line-height: 54px;
    margin-bottom: 10px;
}

.aprTeamWrap{
    display: flex;
    flex-direction: column;
    border-style: dashed;
    border-color: #676767;
    border-width: 1px 1px 0 1px;
}

.aprTeamWrap > div{
    flex: 0 0 auto;
    width: 100%;
}

.aprList{
    margin-top: 80px;
    font-size: 14px;
    font-weight: 300;
}

.aprList li{
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
}

.aprList .aprWidgetWrap{
    max-width: 210px;
}

.aprList li:nth-child(even) .aprWidgetWrap{
    margin-right: 0;
    margin-left: auto;
}

.aprList li:before,
.aprList li:after{
    position: absolute;
    content: "";
    border: dashed #667676;
}

.aprList li:before{
    top: 11px;
    left: 0;
    width: 100%;
    border-width: 1px 0 0;
}

.aprList li:last-child:before{
    width: 50px;
    left: auto;
    right: 0;
}

.aprList li:after{
    bottom: calc(100% - 10px);
    right: 0;
    height: calc(100% + 47px);
    border-width: 0 1px 0 0;
}

.aprList li:nth-child(even){
    text-align: right;
}

.aprList li:first-child:after{
    height: calc(100% - 90px);
}

.aprList li:nth-child(odd):after{
    left: 0;
    right: auto;
    bottom: calc(100% - 10px);
    top: auto;
}

.aprList .h3{
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 20px;
    letter-spacing: normal;
    transition: color .35s ease;
}

.aprList .h3 span{
    padding: 5px;
    background-color: #292727;
    position: relative;
    z-index: 1;
}

/* .aprList li:hover .h3, */
.aprList li.active .h3{
    color: var(--clrTheme);
}

.atBlock{
    padding: 49px 20px 0;
    color: var(--grey100);
    font-size: 16px;
    line-height: 28px;
}

.approachBlock{
    background-color: #292727;
    padding-bottom: 30px;
}

.atBlock.bgGradiantGray{
    box-shadow: none;
}

.atBlock .h2{
    font-size: 30px;
    font-weight: 500;
    line-height: 39px;
    margin-bottom: 12px;
    letter-spacing: 1px;
}

.atBlock .h2 .abhTitle{
    font-size: 20px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.72px;
    display: block;
    margin-bottom: 25px;
}

.teamBlock{
    border-left: 1px dashed #676767;
    overflow: hidden;
}

.leadsWrap{
    position: relative;
    padding-top: 46px;
    margin-top: 30px;
}

.leadsWrap:before{
    position: absolute;
    content: '';
    top: 0;
    left: -51px;
    width: calc(100% + 102px);
    border-width: 1px 0 0;
    border-style: dashed;
    border-color: #676767;
}

.leadTeamWidget{
    display: flex;
    flex-direction: column;
    column-gap: 36px;
}

.leadsWrap .leadTeamWidget + .leadTeamWidget{
    margin-top: 60px;
}

.leadTeamWidget .imgWrap{
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    margin-bottom: 35px;
}

.leadTeamWidget .imgWrap:before{
    position: absolute;
    content: '';
    width: 155px;
    height: 155px;
    border-radius: 50%;
    background-color: var(--clrYellow);
    mix-blend-mode: darken;
}

.leadTeamWidget [data-position="left"]:before{
    top: -64px;
    left: -48px;
    pointer-events: none;
}

.leadTeamWidget [data-position="center"]:before{
    top: -97px;
    left: 33px;
    pointer-events: none;
}

.leadTeamWidget .imgWrap img{
    border-radius: inherit;
}

.leadTeamWidget .h3{
    color: var(--clrTheme);
    font-size: 24px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 18px;
    letter-spacing: normal;
}

.leadTeamWidget .h3 .desigTitle{
    color: var(--themeWhite);
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    display: block;
    margin-top: 5px;
    text-transform: none;
}

/*****************************
    Pricing_Page 
******************************/
.pricingSection{
    padding-top: 80px;
    padding-bottom: 30px;
}

.pricingSection .omlCol{
    display: flex;
}

.pricingSection .txtOutline .icn{
    width: 47px;
    height: 47px;
    background-color: var(--clrTheme);
    border-radius: 50%;
    font-size: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin-left: 5px;
}

.pricingSection .sectionHeader{
    margin-bottom: 100px;
    font-size: 18px;
    line-height: 35px;
    font-weight: 300;
}

.planArticle{
    position: relative;
    color: rgba(255, 255, 255, .5);
    background: linear-gradient(126deg, rgba(52, 52, 52, 0.30) 13.84%, rgba(150, 150, 150, 0.10) 74.14%);
    max-width: 440px;
    width: 100%;
    margin: 0 auto 40px;
}

.planArticle[data-type="Popular"] .plnInnerWrap:before{
    position: absolute;
    content: '';
    right: 16px;
    top: -58px;
    width: 120px;
    height: 120px;
    background-image: url('../images/ico04.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    pointer-events: none;
}

.planArticle[data-type="Popular"][data-count="2"] .plnInnerWrap:before{
    right: 0;
}

.planArticle .plnInnerWrap{
    position: relative;
    z-index: 1;
    padding: 50px 15px 24px;
}

.planArticle:before,
.planArticle:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid transparent;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    transition: all .35s linear;
    transform-origin: 0 0;
}

.planArticle:before{
    background: linear-gradient(115deg, rgba(255,255,255,0.7) 0%, rgba(148,148,148,0.63) 15%, rgba(108,108,108,0.4) 40%, rgba(65,65,65,0.4) 70%, rgba(52,52,52,0.6) 100%) border-box;
}

.planArticle:after{
    background: linear-gradient(115deg, rgba(249,186,33,1) 0%, rgba(251,177,18,1) 15%, rgba(108,108,108,0.4) 40%, rgba(65,65,65,0.4) 70%, rgba(52,52,52,0.6) 100%) border-box;
    opacity: 0;
    visibility: hidden;
}

.planArticle:hover:before{
    opacity: 0;
    visibility: hidden;
}

.planArticle:hover:after{
    opacity: 1;
    visibility: visible;
}

.planArticle .planHead,
.planArticle .planBody{
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 1px dashed #676767;
    padding-bottom: 25px;
    margin-bottom: 25px;
}

.planArticle .sbTtle{
    display: block;
    font-size: 16px;
    line-height: 25px;
    margin-top: 17px;
    color: rgba(255, 255, 255, .5);
    letter-spacing: normal;
}

.planArticle .h1{
    font-size: 30px;
    color: var(--themeWhite);
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -1.08px;
    margin-bottom: 25px;
}

.planArticle .plnPrice{
    color: var(--clrTheme);
    font-size: 36px;
    font-weight: 500;
    line-height: 43px;
    letter-spacing: normal;
}

.planArticle .plnPrice .sbTtle{
    margin-top: 10px;
}

.planArticle .h3{
    color: var(--themeWhite);
    font-size: 22px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.66px;
    margin-bottom: 18px;
    padding-top: 10px;
}

.planArticle .btn{
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: var(--themeBlack);
    border-color: var(--themeBlack);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.planArticle .btn:hover{
    background-color: var(--clrTheme);
    border-color: var(--clrTheme);
    color: var(--themeBlack);
}

.planArticle .ftrList{
    color: rgba(255, 255, 255, .5);
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 300;
}

.planArticle .ftrList li + li{
    margin-top: 7px;
}

.planArticle .ftrList > li:before{
    top: 12px;
}

.addOnSection{
    padding-bottom: 50px;
}

.addOnSection .contactWidget .schDisLink,
.caseStudyDetail .contactWidget .schDisLink{
    text-decoration: underline;
    transition: color .35s ease;
}

.addOnSection .contactWidget .schDisLink:hover,
.caseStudyDetail .contactWidget .schDisLink:hover{
    color: var(--themeWhite);
}

.bdrGradiant,
.bdrGradiant > div{
    position: relative;
}

.bdrGradiant:before,
.bdrGradiant:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid transparent;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    transition: all .35s linear;
    transform-origin: 0 0;
    border-radius: inherit;
}

.bdrGradiant:after{
    transform: scale(1.005);
    opacity: 0;
    visibility: hidden;
}

.bdrGradiant:hover:before{
    transform: scale(1.005);
    opacity: 0;
    visibility: hidden;
}

.bdrGradiant:hover:after{
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}

.gradBlue:before{
    background: linear-gradient(126deg, rgba(89,60,252,1) 0%, rgba(90,60,252,1) 15%, rgba(108,108,108,0.4) 40%, rgba(65,65,65,0.4) 70%, rgba(52,52,52,0.6) 100%) border-box;
}

.gradBlue:after{
    background: linear-gradient(-58deg, rgba(89,60,252,1) 0%, rgba(90,60,252,1) 15%, rgba(108,108,108,0.4) 40%, rgba(65,65,65,0.4) 70%, rgba(52,52,52,0.6) 100%) border-box;

}

.gradGray:before{
    background: linear-gradient(115deg, rgba(255,255,255,0.7) 0%, rgba(148,148,148,0.63) 15%, rgba(108,108,108,0.4) 40%, rgba(65,65,65,0.4) 70%, rgba(52,52,52,0.6) 100%) border-box;
}

.gradGray:after{
    background: linear-gradient(-69deg, rgba(255,255,255,0.7) 0%, rgba(148,148,148,0.63) 15%, rgba(108,108,108,0.4) 40%, rgba(65,65,65,0.4) 70%, rgba(52,52,52,0.6) 100%) border-box;
}

.bdrGradiant > div{
    z-index: 1;
}

.bgGradiantGray{
    background: linear-gradient(126deg, rgba(52, 52, 52, 0.30) 13.84%, rgba(150, 150, 150, 0.10) 74.14%);
    backdrop-filter: blur(5px);
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.20);
}

.addOnWidget{
    padding: 25px 13px;
    color: var(--grey100);
    font-size: 20px;
    cursor: pointer;
}

.addOnWidget .wrapInner{
    display: flex;
    flex-wrap: wrap;
    /* column-gap: 15px; */
}

.addOnWidget .rqdTxt{
    display: block;
    color: var(--themeWhite);
    margin-top: 13px;
    margin-bottom: 41px;
}

.addOnWidget .decpWrap{
    padding: 21px 15px;
}

.addOnWidget .addOnPrice{
    color: var(--clrTheme);
    font-size: 35px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: normal;
}

.addOnWidget .prcTtl{
    color: #FFF;
    font-size: 18px;
    font-weight: 300;
    line-height: 19px;
    display: block;
    margin-top: 15px;
}

.addOnWidget .imgWrap{
    max-width: 415px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.bdgeAddOn{
    border-radius: 100px;
    background-color: #5C3CFD;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Archivo', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.6px;
    padding: 6px 19px;
    margin-bottom: 33px;
    display: inline-block;
    color: var(--themeWhite);
}

.addOnWidget .h1{
    font-size: 35px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: -1.2px;
    margin-bottom: 23px;
}

.addOnSection .contactWidget{
    padding-top: 45px;
    padding-bottom: 49px;
    margin-bottom: 35px;
}

.addOnSection .contactWidget .icnWrap,
.caseStudyDetail .contactWidget .icnWrap{
    margin-bottom: 35px;
}

.addOnSection .contactWidget .h1,
.caseStudyDetail .contactWidget .h1{
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.9px;
    margin-bottom: 23px;
}

/*****************************
    Service_Detail_Page 
******************************/
.serviceDetailSection{
    padding-top: 70px;
    padding-bottom: 50px;
}

.serviceDetailSection .sectionHeader{
    font-weight: 300;
    margin-bottom: 100px;
}

.serviceDetailSection .sectionHeader .imgWrap{
    margin-bottom: 54px;
}

.serviceWidget{
    padding: 50px 20px 55px;
    color: var(--grey100);
    font-size: 16px;
    font-weight: 300;
    line-height: 30px;
    position: relative;
    max-width: 1129px;
    margin-left: auto;
    margin-right: auto;
}

.serviceWidget .txtTag{
    position: absolute;
    left: 50%;
    padding: 18px 28px;
    width: max-content;
    transform: translateX(-50%) rotate(-3.131deg);
    transition: all .35s ease;
}

.serviceWidget .txtTag:hover{
    background-color: var(--clrYellow);
}

.serviceWidget:hover .txtTag{
    transform: translateX(-50%) rotate(0);
}

.serviceWidget[data-tag="top"]{
    padding-top: 80px;
}

.serviceWidget[data-tag="bottom"]{
    padding-bottom: 100px;
}

.serviceWidget[data-tag="bottom"] .txtTag{
    bottom: -118px;
}

.serviceWidget[data-tag="top"] .txtTag{
    top: -105px;
}

.serviceWidget .h2{
    font-weight: 600;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: normal;
}

.srcvWdgAlt{
    padding-left: 0;
    padding-right: 0;
}

.srcvWdgAlt .h2{
    color: var(--clrTheme);
    max-width: 350px;
    margin-left: 0;
    margin-bottom: 35px;
}

.srcvWdgAlt .ftrList{
    font-size: 20px;
    line-height: 33px;
    color: var(--grey100);
    max-width: 510px;
}

.srcvWdgAlt .ftrList li:before{
    top: 13px;
}

.srcvWdgAlt .ftrList li + li{
    margin-top: 15px;
}

.srcvWdgAlt .ftrList li span{
    color: var(--themeWhite);
}

/*****************************
    Contact_Us_Page 
******************************/
.contactUsSection{
    padding-top: 50px;
    padding-bottom: 50px;
}

.omlRow{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.omlCol{
    padding-left: 15px;
    padding-right: 15px;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
}

.contactUsSection .contactForm .omlRow{
    column-gap: 0;
}

.contactUsSection .omlCol40{
    flex: 0 0 auto;
    width: calc(40% - 50px);
}

.contactUsSection .omlCol60{
    flex: 0 0 auto;
    width: calc(60% - 50px);
}

.icnCircle{
    width: 35px;
    height: 35px;
    background-color: var(--clrTheme);
    border-radius: 50%;
    font-size: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    color: var(--themeBlack);
    margin-left: 7px;
}

.contactUsSection .emlTxt{
    font-size: 16px;
    color: var(--clrTheme);
}

.contactUsSection .emailLink{
    font-size: 25px;
    line-height: 33px;
    font-weight: 500;
    letter-spacing: -1.02px;
    color: var(--themeWhite);
}

.cntctBtmRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px 10px;
    flex-wrap: wrap;
    margin-top: 19px;
}

.cntctBtmRow > div{
    display: flex;
    align-items: center;
    gap: 10px 20px;
    flex-wrap: wrap;
}

.btnIcn{
    border-radius: 100px;
    background-color: var(--clrTheme);
    padding: 15px 25px 15px 23px;
    max-width: 207px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: var(--themeBlack);
    font-weight: 600;
    /* overflow: hidden; */
}

.btnIcn .btnTxt{
    margin-left: 15px;
}

.btnIcn:before,
.btnIcn:after{
    position: absolute;
    content: "";
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: inherit;
    border: 3px solid transparent;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    transition: all .35s linear;
    transform-origin: 0 0;
}

.btnIcn:before{
    background: linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(126,126,126,0.4) 33.333%, rgba(84,84,84,0.4) 66.666%, rgba(79,79,79,0.6) 100%) border-box;
}

.btnIcn:after{
    opacity: 0;
    visibility: hidden;
    transform: scale(1.025);
    background: linear-gradient(-94deg, rgba(255,255,255,0.7) 0%, rgba(126,126,126,0.4) 33.333%, rgba(84,84,84,0.4) 66.666%, rgba(79,79,79,0.6) 100%) border-box;
}

.btnIcn:hover:before{
    opacity: 0;
    visibility: hidden;
    transform: scale(1.025);
}

.btnIcn:hover:after{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.contactUsSection .contactWidget{
    background: linear-gradient(126deg, rgba(52, 52, 52, 0.30) 13.84%, rgba(150, 150, 150, 0.10) 74.14%);
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(5px);
    padding: 0;
    margin-bottom: 50px;
}

.contactUsSection .contactWidget,
.contactWidget .imgWrap{
    position: relative;
}

.contactWidget .imgWrap:before,
.contactWidget .imgWrap:after,
.contactUsSection .contactWidget:before,
.contactUsSection .contactWidget:after{
    position: absolute;
    content: "";
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: inherit;
    border: 3px solid transparent;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    transition: all .35s linear;
    transform-origin: 0 0;
}

.contactWidget .imgWrap:before{
    background: linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(126,126,126,0.4) 33.333%, rgba(84,84,84,0.4) 66.666%, rgba(79,79,79,0.6) 100%) border-box;
}

.contactWidget .imgWrap:after{
    background: linear-gradient(-90deg, rgba(255,255,255,0.7) 0%, rgba(126,126,126,0.4) 33.333%, rgba(84,84,84,0.4) 66.666%, rgba(79,79,79,0.6) 100%) border-box;
}

.contactUsSection .contactWidget:before{
    background: linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(148,148,148,0.63) 8%, rgba(108,108,108,0.4) 40%, rgba(65,65,65,0.4) 70%, rgba(52,52,52,0.6) 100%) border-box;
}

.contactUsSection .contactWidget:after{
    background: linear-gradient(-90deg, rgba(255,255,255,0.7) 0%, rgba(148,148,148,0.63) 8%, rgba(108,108,108,0.4) 40%, rgba(65,65,65,0.4) 70%, rgba(52,52,52,0.6) 100%) border-box;
}

.contactWidget .imgWrap:after,
.contactUsSection .contactWidget:after,
.contactWidget:hover:before,
.contactWidget:hover .imgWrap::before{
    transform: scale(1.005);
    opacity: 0;
    visibility: hidden;
}

.contactWidget:hover:after,
.contactWidget:hover .imgWrap:after{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.contactWidget .imgWrap{
    padding: 23px 31px 42px;
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.20);
}

.contactWidget .descWrap{
    padding: 45px 20px 40px;
    position: relative;
    z-index: 1;
}

.contactUsSection .contactWidget .circle{
    width: 100px;
    height: 100px;
}

.contactForm .btnSubmit{
    cursor: pointer;
    max-width: unset;
}

.contactForm .btnWrap{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px 21px;
}

.contactForm .btnWrap .ectTxt{
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: var(--white);
    max-width: 210px;
    text-align: center;
}

.contactForm .btnSubmit[disabled]{
    cursor: not-allowed;
    pointer-events: none;
    opacity: .7;
}

.successText{
    margin-top: 5px;
    display: inline-block;
    color: #188918;
    color: #459d57;
    font-size: 16px;
}

/* .contactForm .btnSubmit[disabled] img,
.contactForm .btnSubmit[disabled] .btnTxt{
    opacity: .5;
}

.contactForm .btnSubmit[disabled]:hover{
    background-color: var(--clrTheme);
} */

.contactForm .btnRow{
    margin-top: 20px;
    margin-bottom: 70px;
}

.contactForm .formErrors{
    font-size: 14px;
    color: rgba(255, 0, 0, .35);
    color: rgba(255, 117, 117, .6);
}

/*****************************
    404_Page 
******************************/
.notFoundSection{
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 100px - 468px);
}

.notFoundSection .btn{
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: fit-content;
}

.notFoundSection .sectionHeader{
    margin-bottom: 0;
}

.notFoundSection .h1 .txtOutline{
    font-size: 100px;
    line-height: 105px;
}

.starShape{
    top: -1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 100px 173.2px 100px;
    border-color: transparent transparent rgba(249, 186, 33, .8) transparent;
    position: relative;
}

/*****************************
    Case_Study_Page 
******************************/
.caseStudyDetail{
    padding-top: 50px;
    padding-bottom: 50px;
}

.caseStudyDetail .decpWrap:hover .txtTag{
    transform: rotate(0);
}

.caseStudyDetail .sectionHeader{
    margin-bottom: 35px;
}

.caseStudyDetail .postTag{
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    padding: 6px 13px;
    color: var(--themeBlack);
    background-color: var(--clrTheme);
    display: inline-block;
    margin-bottom: 18px;
}

.caseStudyDetail .postTpeTxt{
    display: block;
    font-size: 20px;
    font-weight: 300;
    line-height: 25px;
    color: var(--grey100);
    margin-bottom: 20px;
}

.caseStudyDetail .pstTitle{
    margin-bottom: 10px;
}

.caseStudyDetail .imgWrap,
.fancybox__carousel .fancybox__content {
    border-radius: 10px;
    overflow: hidden;
}

.caseStudyDetail .imgWrap img{
    border-radius: inherit;
    width: 100%;
}

.caseStudyDetail .ftrImg{
    margin-bottom: 60px;
    position: relative;
    padding: 18px 15px;
    border-radius: 10px;
    overflow: hidden;
}

.caseStudyDetail .ftrImg.bdrGradiant:before{
    background: linear-gradient(110deg, rgba(249,186,33,1) 0%, rgba(251,177,18,1) 15%, rgba(108,108,108,0.4) 40%, rgba(159,159,159,0.4) 70%, rgba(237,237,237,0.6) 100%) border-box;
}

.caseStudyDetail .ftrImg.bdrGradiant:hover:before{
    background: linear-gradient(-74deg, rgba(249,186,33,1) 0%, rgba(251,177,18,1) 15%, rgba(108,108,108,0.4) 40%, rgba(159,159,159,0.4) 70%, rgba(237,237,237,0.6) 100%) border-box;
}

.caseStudyDetail .ftrImg:before{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    border-radius: inherit;
}

.imgGaleryWrap .imgWrap{
    display: block;
    width: 100%;
}

.imgGaleryWrap .imgWrap + .imgWrap{
    margin-top: 20px;
}

.caseStudyDetail .contntWrap .hdrWrap{
    color: var(--grey100);
}

.caseStudyDetail .contntWrap .hdrWrap .subTlte{
    display: inline-block;
    font-weight: 500;
    line-height: 23.56px;
    letter-spacing: -0.6px;
    color: var(--white);
    margin-bottom: 7px;
    margin-top: 20px;
}

.caseStudyDetail .contntWrap .hdrWrap .ftrList{
    margin-top: 15px;
    margin-bottom: 50px;
    font-weight: 100;
}

.caseStudyDetail .contntWrap .hdrWrap .ftrList li + li{
    margin-top: 5px;
}

.caseStudyDetail .contntWrap .hdrWrap .ftrList > li:before{
    top: 12px;
}

.caseStudyDetail .contntWrap .hdrWrap h2{
    margin-bottom: 6px;
}

.caseStudyDetail .contntWrap .hdrWrap .txtTag{
    margin-bottom: 40px;
    letter-spacing: -1.05px;
}

.caseStudyDetail .contactWidget{
    margin-bottom: 35px;
}

/***************************** 
    Recent_Works_Page 
*****************************/
.recentWorkSection{
    padding-top: 50px;
}

.recentWorkSection .sectionHeader{
    border-top: 0;
    margin-bottom: 35px;
}

.testimonialSection .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
}

.testimonialSection .col {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.testi-info-wrapper {
    font-size: 18px;
    line-height: 23px;
}

.testimonial-logo {
    width: 120px;
    height: 61px;
    margin-bottom: 51px;
    margin-left: auto;
    margin-right: auto;
}

.testimonial-logo img,
.country-flag-text .flag-image img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: contain;
}

.country-flag-text .flag-image {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 100%;
}

.country-flag-text .flag-image img {
    object-fit: cover;
}

.country-flag-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.country-flag-text .country-name {
    margin-left: 5px;
    font-size: 16px;
}

.client-logo-wrapper {
    border-radius: 24px;
    padding: 51px 10px 30px;
    background: linear-gradient(126.08deg, rgba(255, 255, 255, 0.3) 13.84%, rgba(255, 255, 255, 0.1) 74.14%);
    backdrop-filter: blur(10px);
    box-shadow: 0px 4px 20px 1px #00000033;
    border: 3px solid rgba(255, 255, 255, 0.3);
    text-align: center;
}

.vertical-down .client-logo-wrapper,
.vertical-up .client-logo-wrapper {
    margin-top: 51px;
}

.testimonialSection {
    overflow: hidden;
}

@media (min-width: 450px){
    .aprList .aprWidgetWrap{
        max-width: 270px;
    }
}

.ftrWrapper{
    display: flex;
}

.ftrPoints{
    flex: 1;
    padding: 20px;
    text-align: start;
}

.ftrPoints > ul {
    width: 50%;
}

@media(max-width: 768px){
    .ftrPoints > ul {
        width: 100%;
    }
}

.ftrPoints > ul > li {
    font-size: 20px;
    line-height: 2rem;
    position: relative;
    padding-left: 34px;
    text-align: justify;
}

.ftrPoints > ul > li:before{
    position: absolute;
    content: "\e900";
    top: 14px;
    left: 0;
    font-size: 8px;
    font-family: 'omelatte-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--clrTheme);
}

.ftrPoints > ul > li > span {
    color: white;
}

.textRight{
    margin-left: auto;
}

.footerText{
    padding: 20px;
    line-height: 2rem;
}

@media(max-width: 768px){
    .ftrWrapper{
        flex-direction: column;
    }
    .ftrPoints > ul > li {
        font-size: 16px;
    }
}

.ftrSubPoint{
    color: white;
}

.portfolioIcons {
    display: flex;
    justify-content: space-between;
    padding: 40px 50px;
    color: white;
}

.portfolioIcons > i{
    font-size: 50px;
}

.laravelIcon{
    color: red;
}

.reactIcon{
    color: #42d7f5;
}

.awsIcon{
    color:#e6d797
}

.dockerIcon{
    color: #42d7f5;
}

.stripeIcon {
    color: #42d7f5 ;
}

.cloudeIcon{
    color: orange;
}

.angularIcon{
    color: orangered;
}

.flutterIcon{
    color: #42d7f5;
}

.nodejsIcon{
    color: #459d57;
}

.pythonIcon{
    color: #31d4f5;
}

.shopifyIcon{
    color: #d5f4d5;
}

.arrow {
    width: 0;
    height: 0;
    border-style: solid;
    cursor: pointer;
  }
  
  .arrow.forward {
    border-width: 10px 0 10px 15px;
    border-color: transparent transparent transparent #e1e0e0; 
  }
  
  .arrow.backward {
    border-width: 10px 15px 10px 0;
    border-color: transparent #e1e0e0 transparent transparent; 
  }
  
  .arrow {
    display: inline-block; 
    transform: scale(1.5); 
  }
  
  .webLink{
    display: flex;
    justify-content: center;
  }

  .webLink li {
    background: #FBB112;
    color: black;
    padding: 0 10px;
  }

  .readmore{
    text-decoration: underline;
  }

  .portfolioImgWrap{
    max-width: 850px;
    margin: 0 auto;
  }
.portfolioImgWrap ul{
    justify-content: flex-end;
    margin-top: 15px;
}

.portfolioImgWrap img{
    width: 100%;
}

.testimonialSection > * {
    flex: 0 0 50%; 
    box-sizing: border-box;
}

.testimonial-child-1{
    border-radius: 22px;
    padding: 40px 20px 15px;
    background: black;
}

.testimonialSection .happy{
    font-size: 20px;
    font-weight: 700;
    transform: rotate(-3.13deg);
    color: black;
    background: #F9BA21;
    display: inline-block;
    padding: 2px 10px;
}

.testimonialSection .customer{
    font-size: 35px;
    margin-top: 13px;
    font-weight: 900;
    color: #252220;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px var(--themeWhite);
}

.testimonialSection .stories{
    margin-top: 13px;
    font-size: 35px;
    font-weight: 900;
}

.testimonialSection .testimonial-description{
    margin-top: 50px;
    color: #FFFFFF;
    font-size: 22px;
    font-weight: 250;
    text-align: justify;
}

.testimonialSection .testimonial-footer{
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.testimonialSection .testimonial-client-name{
    margin-top: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.testimonialSection .testimonial-client-name .testimonial-name {
    background: #2AB5E8;
    color: white;
    border-radius: 100%;
    padding: 15px;
    align-self: flex-start;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    flex-shrink: 0;
}
.testimonialSection .testimonial-client-name .testimonial-ceo {
    color: #817c7c;
}
.testimonialSection .testimonial-img {
    height: 59px;
    width: 71px;
}

.with-clients{
    display: flex;
    align-items: center;
}

.working-with-clients-section{
    display: flex;
    gap: 30px;
    overflow: hidden;
    margin-top: 50px;
}

.working-with-clients-section > *{
    display: flex;
    flex-direction: column;
}

marquee-component {
    overflow: hidden;
    height: 702px;
    margin-top: 0;
}

.marquee-component .marquee-component_col + .marquee-component_col {
    margin-top: 45px;
}

@media (min-width: 576px){
    .container{
        max-width: 540px;
    }

    h1, .h1{
        font-size: 50px;
        line-height: 60px;
    }

    .blogPost .descpWrap .h1{
        font-size: 40px;
        line-height : 49px;
    }

    .hiwList li,
    .contactForm .csfCol.csfColHalf{
        flex: 0 0 auto;
        width: 50%;
    }

    .appFtrWdgt{
        display: flex;
        column-gap: 50px;
    }

    .appFtrWdgt .imgWrap{
        margin-bottom: 0;
    }

    .appFtrWdgt .titleTxt:before{
        left: -20px;
        top: 4px;
        width: 1px;
        height: 87%;
    }

    .blogSection .sectionHeader{
        margin-bottom: 100px;
    }

    .tagList > li > a{
        font-size: 14px;
        line-height: 16px;
    }

    .blogPost .blgCat{
        font-size: 16px;
    }

    .pricingSection .sectionHeader .txtOutline{
        max-width: 89%;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .blogPost[data-feature="Latest"]:before{
        top: -47px;
        left: -33px;
        width: 120px;
        height: 120px;
    }

    .blogPost{
        margin-bottom: 50px;
    }

    .blogSection .slick-slider .blogPost{
        padding-left: 10px;
        padding-right: 10px;
    }

    .contactSection .sectionHeader .h1 .txtTag{
        font-size: 19px;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .ReactModal__Overlay{
        padding: 30px;
    }

    .scheCallModal .clsBtn{
        right: 45px;
    }

    .contactSection .estCstBtn{
        padding-top: 0;
    }

    .contactUsSection{
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .contactWidget .descWrap{
        padding: 66px 31px 51px;
    }

    .contactUsSection .contactWidget .descWrap{
        padding-left: 25px;
        padding-right: 25px;
    }

    .contactUsSection .contactWidget .circle{
        width: 136px;
        height: 136px;
    }
    
    .planArticle .plnInnerWrap{
        padding-left: 26px;
        padding-right: 26px;
    }

    .planArticle[data-type="Popular"] .plnInnerWrap:before{
        right: 26px;
        top: -71px;
        width: 144px;
        height: 144px;
    }

    .addOnSection .contactWidget .h1,
    .caseStudyDetail .contactWidget .h1{
        font-size: 30px;
    }

    .serviceWidget{
        padding-left: 30px;
        padding-right: 30px;
    }
    
    .tmList > li{
        width: 50%;
    }

    .atBlock{
        padding-left: 30px;
        padding-right: 30px;
    }

    .aprList{
        font-size: 15px;
    }

    .aprList li{
        padding-left: 36px;
        padding-right: 36px; 
    }

    .aprList .h3{
        font-size: 20px;
    }

    .aprList .aprWidgetWrap{
        max-width: 310px;
    }

    .aprList li:first-child:after {
        height: calc(100% - 70px);
    }

    .caseStudyDetail .sectionHeader{
        margin-bottom: 57px;
    }

    .caseStudyDetail .imgWrap,
    .caseStudyDetail .ftrImg,
    .fancybox__carousel .fancybox__content{
        border-radius: 18px;
    }

    .recentWorkSection .sectionHeader{
        margin-bottom: 50px;
    }

    .wdpsList{
        font-size: 16px;
        gap: 13px 1.7%;
    }
}

@media (min-width: 768px){
    .container{
        max-width: 720px;
    }

    h1, .h1{
        font-size: 60px;
        line-height: 70px;
    }

    h2, .h2{
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 30px;
    }

    .blogPost .descpWrap .h1{
        font-size: 50px;
        line-height : 59px;
    }

    .hiwWidget{
        border-radius: 161px;
        padding-top: 65px;
        padding-bottom: 107px;
        max-width: unset;
    }

    .hiwWidget p{
        padding-left: 40px;
        padding-right: 40px;
    }

    .contactSection .csRow .csCol04{
        flex: 0 0 auto;
        width: 40%;
        width: 35%;
    }

    .contactSection .csRow .csCol08{
        flex: 0 0 auto;
        width: 60%;
        width: 55%;
    }

    .notFoundSection{
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .txtTag{
        font-size: 25px;
        line-height: 27px;
        padding: 10px 18px;
    }

    .bannerSection .txtTag{
        margin-bottom: 28px;
    }

    .gtYrAppSection{
        padding-bottom: 80px;
    }

    .blogPost .imgWrap{
        margin-bottom: 25px;
    }

    .blogPost .subTlte{
        font-size: 20px;
    }

    .blogSection .slick-slider{
        padding-top: 50px;
    }

    .blogSection .slick-slider .blogPost{
        padding-left: 20px;
        padding-right: 20px;
    }

    .blogSection{
        padding-bottom: 50px;
    }
    
    .hwItWrkSection{
        padding-top: 102px;
        padding-bottom: 100px;
    }

    .hiwWidget .hiwTitle{
        font-size: 22px;
        padding-top: 18px;
        padding-bottom: 18px;
    }

    .contactWidget{
        padding: 49px 35px 51px 43px;
    }

    .contactWidget .circle{
        margin-bottom: 36px;
    }
    
    .contactWidget .h2{
        font-size: 36px;
        line-height: 39px;
        margin-bottom: 26px;
    }

    .contactSection .emlTxt{
        font-size: 20px;
        margin-bottom: 23px;
    } 

    .contactSection .emailLink{
        font-size: 40px;
        line-height: 43px;
    }

    .contactSection{
        padding-bottom: 100px;
    }

    .secvicesSection .sectionHeader{
        padding-top: 65px;
        margin-bottom: 74px;
    }

    .srvcWidget{
        padding: 40px 50px 30px;
        border-bottom-color: rgba(242, 242, 242, .23);
    }

    .servcList > li{
        flex: 0 0 auto;
        width: 50%;
        max-width: unset;
        border-left: 1px solid rgba(242, 242, 242, .23);
    }

    .servcList > li:nth-child(odd){
        border-left: 0;
    }

    .servcList > li:nth-last-child(1) .srvcWidget,
    .servcList > li:nth-last-child(2) .srvcWidget{
        border-bottom: 0;
    }

    .blogSection .sectionHeader{
        margin-bottom: 125px;
    }

    .blogPost[data-feature="Latest"]:before{
        top: -54px;
        left: -40px;
        width: 140px;
        height: 140px;
    }

    .contactForm .csfCol50{
        flex: 0 0 auto;
        width: 50%;
    }

    .contactSection .sectionHeader .h1 .txtTag{
        font-size: 23px;
    }

    .estCstBtn{
        padding-top: 0;
    }

    .contactUsSection{
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .icnCircle{
        width: 45px;
        height: 45px;
        font-size: 14px;
    }

    .contactUsSection .emailLink{
        font-size: 34px;
        line-height: 36px;
    }

    .contactUsSection .emlTxt{
        font-size: 20px;
    }

    .pricingSection .omlCol33{
        flex: 0 0 auto;
        width: 50%;
    }

    .pricingSection .sectionHeader{
        margin-bottom: 115px;
        font-size: 20px;
    }

    .addOnWidget .h1,
    .addOnWidget .addOnPrice{
        font-size: 40px;
        line-height: 43px;
    }

    .serviceWidget{
        padding: 71px 50px 75px;
        font-size: 18px;
        line-height: 33px;
    }
    
    .tmList > li{
        width: 33.333%;
    }

    .aboutUsSection .sectionHeader .h1{
        margin-bottom: 54px;
    }

    .aboutUsSection .sectionHeader strong{
        margin-bottom: 32px;
    }

    .wdpsList{
        font-size: 18px;
        line-height: 25px;
        gap: 20px 63px;
        gap: 13px 3%;
        margin-bottom: 120px;
    }

    .wdpsList > li{
        padding: 16px 20px;
    }

    .teamWidget .imgWrap .icn{
        top: -5px;
        right: -5px;
        font-size: 20px;
    }

    .atBlock{
        padding-left: 45px;
        padding-right: 45px;
    }

    .atBlock .h2{
        font-size: 40px;
        line-height: 49px;
    }
    
    .atBlock .h2 .abhTitle{
        font-size: 24px;
        line-height: 25px;
        margin-bottom: 40px;
    }
    
    .aboutUsSection{
        padding-top: 80px;
        padding-bottom: 100px;
    }

    .aprList{
        font-size: 16px;
        margin-top: 90px;
    }

    .aprList .h3{
        font-size: 22px;
    }

    .aprList .aprWidgetWrap{
        max-width: unset;
    }

    .aprList li:nth-child(even) .aprWidgetWrap{
        padding-left: 30px;
    }

    .aprList li:first-child:after {
        height: calc(100% - 70px);
        height: 74px;
    }

    .imgGaleryWrap .imgWrap + .imgWrap{
        margin-top: 30px;
    }

    .caseStudyDetail .postTag{
        margin-bottom: 26px;
        font-size: 18px;
        line-height: 21px;
        padding: 8px 15px;
    }
    
    .caseStudyDetail .postTpeTxt{
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    .caseStudyDetail .ftrImg{
        padding: 25px 23px 28px 23px;
    }

    .recentWorkSection{
        padding-top: 70px;
    }

    .recentWorkSection .sectionHeader{
        margin-bottom: 50px;
    }

    .contactForm .formErrors{
        font-size: 16px
    }

    .testimonial-child-1{
        padding: 60px 60px 55px;
    }

    .testimonialSection .col {
        width: 50%;
    }
}

@media (min-width: 832px){
    .scheCallModal .clsBtn{
        top: 40px;
        left: 42px;
        right: auto;
    }
}

@media (min-width: 992px){
    #pageWrapper {
        font-size: 18px;
        line-height: 33px;
    }

    .container{
        max-width: 960px;
    }

    h1, .h1{
        font-size: 70px;
        line-height: 80px;
        margin-bottom: 31px;
    }

    h2, .h2{
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 40px;
    }

    .bannerSection{
        padding-top: 87px;
        padding-bottom: 80px;
    }

    .bnrWidget .wdgTag{
        font-size: 24px;
        line-height: 30px;
        padding: 12px 28px;
    }

    .bnrWidget .gradLne{
        max-width: 100%;
        margin-left: 28px;
        width: calc(100% - 28px);
    }

    .hwItWrkSection .sectionHeader{
        margin-bottom: 81px;
    }

    .hiwList li{
        width: 33.333%;
    }

    .hiwWidget p{
        padding-left: 15px;
        padding-right: 15px;
    }

    .formControl{
        padding-left: 29px;
        padding-right: 29px;
    }
    
    .notFoundSection .h1 .txtOutline{
        font-size: 115px;
        line-height: 120px;
    }

    .serviceDetailSection .sectionHeader,
    .caseStudyDetail .sectionHeader{
        font-size: 20px;
    }

    .pricingSection .sectionHeader .h1 + p,
    .serviceDetailSection .sectionHeader .h1 + p{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .serviceDetailSection .sectionHeader .h1 + p{
        max-width: 38%;
    }

    .pricingSection .sectionHeader .h1 + p{
        max-width: 73%;
    }

    .bannerSection .bnrCol70{
        flex: 0 0 auto;
        width: calc(60% - 15px);
    }
    
    .bannerSection .bnrCol30{
        flex: 0 0 auto;
        width: calc(40% - 15px);
    }

    .gtYrAppSection .gyaCol40{
        flex: 0 0 auto;
        width: calc(45% - 25px);
    }

    .gtYrAppSection .gyaCol60{
        flex: 0 0 auto;
        width: calc(55% - 25px);
    }

    .gtYrAppSection .sctnTagElem{
        margin-bottom: 105px;
    }

    .gtYrAppSection .sctnTagElem span{
        font-size: 20px;
    }

    .appFtrWdgt{
        font-size: 20px;
        line-height: 36px;
    }
    
    .appFtrWdgt .titleTxt{
        font-size: 30px;
        line-height: 35px;
    }

    .appFtrWdgt .titleTxt:before{
        top: 7px;
    }

    .srvcWidget .srvTitle{
        margin-bottom: 15px;
    }
    
    .srvcWidget .readMoreLnk{
        margin-top: 53px;
    }

    .blogPost[data-feature="Latest"]:before{
        top: -54px;
        left: -36px;
        width: 140px;
        height: 140px;
    }

    .blogSection .sectionHeader{
        /* margin-bottom: 148px; */
        margin-bottom: 0;
    }

    .blogPost[data-feature="Latest"]{
        margin-top: 150px;
    }

    .blogSection .omlRow{
        column-gap: 50px;
    }

    .blogSection .bgsCol45{
        flex: 0 0 auto;
        width: calc(45% - 25px);
    }
    
    .blogSection .bgsCol55{
        flex: 0 0 auto;
        width: calc(55% - 25px);
    }

    .blogPost .imgWrap{
        margin-bottom: 39px;
    }

    .blogSection .slick-slider .blogPost{
        padding-left: 25px;
        padding-right: 25px;
    }

    .contactSection .contactForm{
        margin-bottom: 47px;
        padding-top: 0;
    }

    .contactSection .omlCol,
    .contactUsSection .omlCol{
        flex: 0 0 auto;
    }

    .contactSection .csCol40,
    .contactUsSection .csCol40{
        width: 45%;
    }

    .contactSection .csCol60,
    .contactUsSection .csCol60{
        width: 55%;
    }

    textarea.formControl{
        min-height: 299px;
        padding-top: 25px;
    }

    .contactSection .sectionHeader .h1 .txtTag{
        font-size: 28px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .contactUsSection .contactWidget .schDisLink{
        font-size: 18px;
    }

    .contactUsSection{
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .cntctBtmRow{
        column-gap: 20px;
    }
    
    .cntctBtmRow > div{
        column-gap: 25px;
    }

    .planArticle .h1{
        /* font-size: 33px; */
    }
    
    .pricingSection .omlCol33 {
        width: 33.333%;
    }

    .planArticle .plnInnerWrap{
        padding-left: 15px;
        padding-right: 15px;
    }

    .planArticle[data-type="Popular"] .plnInnerWrap:before{
        right: 16px;
        top: -58px;
        width: 120px;
        height: 120px;
    }

    .pricingSection .sectionHeader{
        margin-bottom: 132px;
        font-size: 22px;
        line-height: 41px;
    }

    .addOnSection .omlCol,
    .caseStudyDetail .decpWrap .omlCol{
        flex: 0 0 auto;

    }

    .addOnSection .omlCol33{
        width: 33.33%;
    }
    
    .addOnSection .omlCol67{
        width: 66.67%;
    }

    .addOnSection{
        padding-top: 50px;
        padding-bottom: 120px;
    }

    .addOnSection .contactWidget{
        margin-bottom: 0;
    }

    .addOnWidget .imgWrap{
        max-width: 300px;
    }

    .addOnSection .contactWidget{
        padding-left: 20px;
        padding-right: 15px;
    }

    .serviceDetailSection{
        padding-top: 97px;
        padding-bottom: 100px;
    }

    .serviceDetailSection .sectionHeader{
        margin-bottom: 138px;
    }

    .serviceWidget{
        padding-right: 79px;
        padding-left: 79px;
        font-size: 20px;
        line-height: 48px;
    }

    .srcvWdgAlt .h2{
        margin-bottom: 0;
    }

    .srcvWdgAlt .wrapIner{
        display: flex;
        /* align-items: center; */
        justify-content: space-between;
        column-gap: 35px;
    }

    .serviceWidget[data-tag="top"] .txtTag{
        top: -147px;
    }
    
    .serviceWidget[data-tag="top"]{
        padding-top: 112px;
    }

    .serviceWidget[data-tag="bottom"]{
        padding-bottom: 127px;
    }

    .serviceWidget[data-tag="bottom"] .txtTag{
        bottom: -145px;
    }

    .teamSection{
        padding-top: 40px;
    }

    .tmInnerWrap{
        position: relative;
        padding-top: 300px;
        padding-bottom: 450px;
    }

    .tmList li{
        position: absolute;
    }
    
    .tmList li:nth-child(1){
        left: -50px;
        top: 162px;
    }
    
    .tmList li:nth-child(2){
        left: 50%;
        transform: translateX(-50%);
        top: 0;
    }
    
    .tmList li:nth-child(3){
        right: -60px;
        top: 216px;
    }
    
    .tmList li:nth-child(4){
        left: 80px;
        bottom: 100px;
    }
    
    .tmList li:nth-child(5){
        right: 110px;
        bottom: 40px;
    }

    .wdpsList{
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 176px;
    }

    .wdpsList > li{
        padding: 16px 40px;
    }

    .aprTeamWrap{
        flex-direction: row;
    }
    
    .aprTeamWrap > div{
        width: 100%;
    }

    .teamSection .detailWidget .h1{
        font-size: 50px;
        line-height: 64px;  
    }

    .teamWidget .h1{
        font-size: 24px;
        line-height: 25px;
    }

    .leadsWrap .leadTeamWidget + .leadTeamWidget{
        margin-top: 74px;
    }

    .atBlock{
        padding-bottom: 120px;
    }

    .aprList .h3 span {
        padding-right: 18px;
        padding-left: 7px;
    }

    .aprList li:nth-child(even) .h3 span{
        padding-left: 18px;
        padding-right: 7px;
    }

    .aprList li {
        padding-left: 20px;
        padding-right: 20px;
    }

    .caseStudyDetail .decpWrap{
        position: relative;
    }

    .atWrapInner,
    .caseStudyDetail .contntWrap{
        position: sticky !important;
        top: 100px;
    }

    .caseStudyDetail{
        padding-top: 84px;
        padding-bottom: 70px;
    }

    .caseStudyDetail .contntWrap .hdrWrap .ftrList{
        font-size: 18px;
    }

    .caseStudyDetail .contactWidget{
        max-width: 440px;
    }

    .caseStudyDetail .ftrImg{
        margin-bottom: 120px;
    }

    .caseStudyDetail .contntWrap .hdrWrap .ftrList{
        margin-top: 27px;
        margin-bottom: 78px;
    }

    .caseStudyDetail .contntWrap .hdrWrap .ftrList li + li{
        margin-top: 10px;
    }

    .caseStudyDetail .contntWrap .hdrWrap .txtTag{
        margin-bottom: 60px;
        padding: 18px 19px 17px;
    }

    .caseStudyDetail .contntWrap .hdrWrap .subTlte{
        margin-bottom: 10px;
        margin-top: 26px;
    }

    .caseStudyDetail .decpWrap .csdCol40{
        width: 40%;
    }

    .caseStudyDetail .decpWrap .csdCol60{
        width: 60%;
    }

    .caseStudyDetail .contactWidget{
        margin-bottom: 0;
    }

    .caseStudyDetail .ftrImg{
        padding: 38px 35px 41px 35px;
    }

    .recentWorkSection .blogPost{
        margin-bottom: 90px;    
    }

    .recentWorkSection{
        padding-top: 90px;
    }

    .teamWidget .imgWrap .icn{
        opacity: 1;
        visibility: visible;
    }

    .contactForm .btnRow{
        margin-top: 35px;
        margin-bottom: 100px;
    }

    .contactForm .btnWrap{
        column-gap: 10px;
    }

    .wthOtSlider .blogPost .imgWrap{
        padding: 15px;
    }

    .testimonialSection .happy{
        margin-top: 40px;
        font-size: 35px;
        padding: 5px 10px;
    }

    .testimonialSection .customer{
        font-size: 75px;
        margin-top: 30px;
    }

    .testimonialSection .stories{
        margin-top: 30px;
        font-size: 75px;
    }

    .testimonialSection .testimonial-description{
        margin-top: 92px;
    }

    .testimonialSection .testimonial-client-name{
        margin-top: 73px;
    }

    .testimonial-logo {
        width: 221px;
        height: 61px;
    }
}

@media (min-width: 1200px){
    .container{
        max-width: 1150px;
    }

    .contactForm .btnWrap{
        column-gap: 21px;
    }

    h1, .h1{
        font-size: 80px;
        line-height: 94px;
    }

    .caseStudyDetail .contntWrap .hdrWrap h2{
        font-size: 70px;
        line-height: 82px;
    }

    .serviceDetailSection .sectionHeader{
        font-size: 22px;
    }

    .callBookAside{
        padding-top: 39px;
        padding-bottom: 41px;
    }

    .secvicesSection .sectionHeader .txtOutline{
        display: block;
        width: 100%;
    }

    .hiwList li{
        width: 25%;
    }
    
    .hiwWidget p{
        padding-left: 40px;
        padding-right: 40px;
    }

    .contactWidget{
        font-size: 20px;
        line-height: 37px;
    }
    
    .gtYrAppSection h1,
    .gtYrAppSection .h1{
        font-size: 70px;
        line-height: 82px;
    }

    .contactUsSection .contactWidget{
        font-size: 22px;
    }

    .contactUsSection .contactWidget .descWrap p{
        padding-left: 6px;
        padding-right: 6px;
    }

    .notFoundSection{
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .notFoundSection .h1 .txtOutline{
        font-size: 130px;
        line-height: 135px;
    }

    .pricingSection .txtOutline .icn{
        width: 60px;
        height: 60px;
        font-size: 18px;
    }

    .txtTag{
        font-size: 32px;
        line-height: 32px;
    }

    .gtYrAppSection .sctnTagElem .txtTag{
        font-size: 35px;
        padding: 17px 24px;
    }

    .bannerSection .bnrCol70{
        width: calc(65% - 15px);
    }
    
    .bannerSection .bnrCol30{
        width: calc(35% - 15px);
    }

    .hiwList li + li:before{
        position: absolute;
        content: "\e902";
        bottom: 55px;
        left: -20px;
        transform: rotate(7deg);
        font-size: 28px;
        font-weight: 900;
        font-family: 'omelatte-icons' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--clrTheme);
        transition: color .35s ease;
    }

    /* .hiwList li + li:before{
        bottom: 55px;
        left: -20px;
        transform: rotate(7deg);
        font-size: 28px;
        top: -21px;
        left: 20%;
        transform: translateX(-50%) rotate(100deg);
        font-size: 20px;
    }

    .hiwList li + li:nth-child(odd):before{
        bottom: auto;
        top: 30px;
        transform: rotate(75deg);
        left: auto;
        right: 20%;
        transform: translateX(-50%) rotate(170deg);
    } */
    
    .hiwList li + li:nth-child(odd):before{
        bottom: auto;
        top: 30px;
        transform: rotate(75deg);
    }

    .servcList > li{
        width: 33.333%;
    }

    .srvcWidget{
        padding: 60px 56px 33px 90px;
    }

    .servcList > li:nth-child(odd){
        border-left: 1px solid rgba(242, 242, 242, .23);
    }

    .servcList > li:nth-child(1),
    .servcList > li:nth-child(4),
    .servcList > li:nth-child(7) {
        border-left: 0;
    }

    .servcList > li:nth-last-child(3) .srvcWidget{
        border-bottom: 0;
    }

    .blogSection .omlRow{
        column-gap: 105px;
        justify-content: center;
    }

    .blogSection .bgsCol45{
        flex: 0 0 auto;
        width: calc(47% - 74px);
    }
    
    .blogSection .bgsCol55{
        flex: 0 0 auto;
        width: calc(53% - 74px);
    }

    .blogPost .imgWrap{
        margin-bottom: 39px;
    }

    .blogSection .slick-slider{
        padding-top: 83px;
    }

    .blogSection .slick-slider .blogPost{
        padding-left: 43px;
        padding-right: 43px;
    }

    .contactSection .omlRow{
        column-gap: 90px;
    }

    .contactUsSection .omlRow{
        column-gap: 60px;
    }

    .contactUsSection .csCol40{
        width: calc(45% - 30px);
    }

    .contactUsSection .csCol60{
        width: calc(55% - 30px);
    }

    .contactSection .csCol40{
        width: calc(45% - 45px);
    }

    .contactSection .csCol60{
        width: calc(55% - 45px);
    }

    .contactForm .omlRow{
        column-gap: 0;
    }

    .contactSection .sectionHeader .h1 .txtTag{
        font-size: 32px;
    }

    .contactUsSection .contactWidget .schDisLink{
        font-size: 20px;
    }

    .contactUsSection{
        padding-top: 135px;
        padding-bottom: 135px;
    }

    .icnCircle{
        width: 60px;
        height: 60px;
        font-size: 18px;
    }

    .contactUsSection .sectionHeader .h1,
    .pricingSection .sectionHeader .h1,
    .caseStudyDetail .pstTitle{
        font-size: 90px;
    }

    .planArticle .h1{
        font-size: 36px;
    }

    .planArticle .plnPrice{
        font-size: 40px;
    }

    .planArticle .sbTtle{
        font-size: 18px;
        line-height: 30px;
    }

    .planArticle .ftrList{
        font-size: 18px;
    }
    
    .planArticle .ftrList li + li{
        margin-top: 10px;
    }

    .planArticle .plnInnerWrap{
        padding-left: 26px;
        padding-right: 26px;
    }

    .planArticle[data-type="Popular"] .plnInnerWrap:before {
        right: 26px;
        top: -71px;
        width: 144px;
        height: 144px;
    }

    .pricingSection .sectionHeader .h1 + p{
        max-width: 57%;
    }

    .addOnSection .contactWidget{
        padding-right: 35px;
        padding-left: 43px;
    }

    .addOnWidget .decpWrap{
        padding-left: 31px;
        padding-right: 31px;
    }

    .addOnSection .omlCol{
        display: flex;
    }

    .addOnWidget .wrapInner{
        flex-wrap: nowrap;
        height: 100%;
    }

    .addOnWidget .imgWrap{
        max-width: 415px;
        height: inherit;
    }

    .addOnWidget .imgWrap img{
        height: inherit;
    }

    .addOnWidget .decpWrap{
        padding-bottom: 0;
    }

    .pricingSection .sectionHeader .txtOutline{
        max-width: 79%;
    }

    .tmList li:nth-child(1){
        left: 0;
    }

    .tmList li:nth-child(3){
        right: 0;
    }

    .tmList li:nth-child(4){
        left: 160px;
    }
    
    .tmList li:nth-child(5){
        right: 200px;
    }

    .teamSection{
        padding-top: 70px;
        padding-bottom: 100px;
    }

    .teamSection .detailWidget .h1{
        font-size: 60px;
        line-height: 74px;  
    }

    .atBlock{
        padding-left: 51px;
        padding-right: 51px;
    }

    .leadTeamWidget{
        flex-direction: row;
    }

    .leadTeamWidget .imgWrap{
        max-width: 200px;
        margin-bottom: 0;
    }

    .aprList li{
        padding-left: 30px;
        padding-right: 30px;
    }
    
    .aprList li:nth-child(even) .aprWidgetWrap{
        padding-left: 20px;
    }

    .atBlock{
        padding-bottom: 206px;
    }

    .caseStudyDetail .decpWrap .omlRow{
        column-gap: 60px;
    }

    .caseStudyDetail .decpWrap .csdCol40{
        width: calc(47% - 30px);
    }

    .caseStudyDetail .decpWrap .csdCol60{
        width: calc(53% - 30px);
    }

    .caseStudyDetail .contntWrap .hdrWrap{
        font-size: 20px;
        line-height: 32px;
    }

    .caseStudyDetail .contntWrap .hdrWrap .txtTag{
        font-size: 35px;
    }

    .caseStudyDetail{
        padding-bottom: 100px;
    }

    .recentWorkSection{
        padding-top: 130px;
    }

    .wdpsList > li + li:before{
        position: absolute;
        content: "\e902";
        top: 24px;
        left: -40px;
        font-size: 15px;
        font-family: 'omelatte-icons' !important;
        speak: never;
        font-style: normal;
        font-weight: 900;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--clrTheme);
        transition: transform .35s ease;
    }

    .wdpsList > li:hover + li:before{
        transform: rotate(45deg);
    }

    .wdpsList > li{
        padding: 16px 46px;
    }

    .wdpsList{
        gap: 20px 63px;
    }

    .contactForm .btnRow{
        margin-top: 42px;
    }

    .contactForm .btnWrap .ectTxt{
        font-size: 20px;
        line-height: 30px;
        max-width: 225px;
    }

    .wthOtSlider .blogPost .imgWrap{
        padding: 22px 18px;
    }

    .wthOtSlider .blogPost[data-feature="Latest"] .imgWrap{
        padding: 48px 32px;
    }
}

@media (min-width: 1330px){
    .container{
        max-width: 1407px;
    }
}

@media (min-width: 1440px){
    .blogPost[data-feature="Latest"]:before{
        left: -55px;
    }    
    
    .contactSection .omlRow{
        column-gap: 148px;
    }

    .contactUsSection .omlRow{
        column-gap: 103px;
    }

    .contactSection .csCol40,
    .contactUsSection .csCol40{
        width: calc(42% - 74px);
    }

    .contactSection .csCol60,
    .contactUsSection .csCol60{
        width: calc(58% - 74px);
    }

    .contactForm .omlRow{
        column-gap: 0;
    }

    .tmList li:nth-child(1){
        left: 0;
    }

    .tmList li:nth-child(3){
        right: 0;
    }

    .tmList li:nth-child(4){
        left: 125px;
    }
    
    .tmList li:nth-child(5){
        right: 280px;
    }

    .leadTeamWidget .imgWrap{
        max-width: 230px;
    }

    .caseStudyDetail .decpWrap .omlRow{
        column-gap: 76px;
    }

    .caseStudyDetail .decpWrap .csdCol40{
        width: calc(42% - 38px);
    }

    .caseStudyDetail .decpWrap .csdCol60{
        width: calc(58% - 38px);
    }
}

@media (min-width: 1550px){
    .blogPost[data-feature="Latest"]:before{
        top: -73px;
        left: -88px;
        width: 186px;
        height: 186px;
    }

    .blogSection .omlRow{
        column-gap: 147px;
    }
}

@media (min-width: 1650px){
    .blogPost[data-feature="Latest"]:before{
        left: -98px;
    }
}

@media (max-width: 767px){
    .testimonialSection .happy {
        margin-top: 0;
        font-size: 20px;
        padding: 0px 10px;
    }

    .testimonialSection .customer {
        font-size: 35px;
        margin-top: 11px;
    }

    .testimonialSection .stories {
        margin-top: 10px;
        font-size: 35px;
    }

    .testimonialSection .testimonial-description {
        margin-top: 30px;
        font-size: 18px;
    }

    .testimonialSection .testimonial-client-name {
        margin-top: 33px;
    }

    .testimonial-child-1 {
        margin-bottom: 25px;
    }

    .testimonial-logo {
        width: 121px;
    }

    .working-with-clients-section {
        margin-top: 20px;
    }
}
