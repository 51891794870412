.phStickyWrap{
    min-height: 66px;
}

.topBar{
    padding: 17px 15px 12px;
}

.mainLogo{
    max-width: 180px;
}

.mainLogo img{
    vertical-align: middle;
}

.hdBtn{
    color: #211E1D;
    font-size: 20px;
    line-height: normal;
    letter-spacing: -0.6px;
    padding: 10px 30px;
    padding: 15px 30px;
    margin-top: 15px;
    margin-left: 20px;
}

.hdBtn .icn{
    font-size: 14px;
    margin-left: 5px;
    display: inline-block;
    transition: transform 0.35s ease;
}

.hdBtn:hover .icn{
    transform: rotate(45deg);
}

.mainHeader{
    border-color: rgba(242, 242, 242, .23);
    border-width: 1px 0;
    border-style: solid;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.mainHeader > .hdBtn{
    display: none;
}

body.mobile-menu-open {
    position: relative;
    overflow: hidden;
}

.navbarToggler {
    position: relative;
    width: 44px;
    height: 44px;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    margin-right: -15px;
}

.justForSpy{
    opacity: 0;
    visibility: hidden;
    position: fixed !important;
    top: 0;
    left: 0;
}

.navbarToggler:before,
.navbarToggler:after,
.togglerIcon {
    position: absolute;
    content: "";
    display: block;
    left: 12px;
    width: 20px;
    height: 2px;
    transform: rotate(0);
    transition: .3s ease-in-out;
    transition-property: transform, width, top, left;
    background-color: var(--white);
}

.navbarToggler:before {
    top: 14px;
}

.navbarToggler:after {
    top: 30px;
}

.mobile-menu-open .navbarToggler:before,
.mobile-menu-open .navbarToggler:after,
.mobile-menu-open .togglerIcon{
    background-color: var(--clrTheme);
}

.navbarNav > li.active,
.navbarNav > li > a:hover,
.navbarNav > li > a.active{
    background-color: var(--clrTheme);
    color: var(--themeBlack);
}

@media (max-width: 767px){
    .collapseWrap{
        position: fixed;
        top: 0;
        right: 0;
        min-height: 100vh;
        height: 100%;
        max-width: 320px;
        width: 100%;
        padding-top: 80px;
        padding-bottom: 30px;
        transform: translateX(320px);
        transition: transform .35s ease;
        background-color: #211E1D;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 999;
        word-wrap: break-word;
    }
    
    .mobile-menu-open .collapseWrap{
        transform: translate(0);
    }
    
    .navbarTogglerClose{
        position: absolute;
        top: 10px;
        right: 10px;
        width: 44px;
        height: 44px;
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;
    }
    
    .navbarTogglerClose:before,
    .navbarTogglerClose:after{
        position: absolute;
        content: "";
        display: block;
        left: 12px;
        width: 20px;
        height: 2px;
        background-color: var(--white);
        transition: all .35s ease;
    }
    
    .navbarTogglerClose:hover:before,
    .navbarTogglerClose:hover:after{
        background-color: var(--clrTheme);
    }
    
    .navbarTogglerClose:before{
        transform: rotate(45deg);
    }
    
    .navbarTogglerClose:after{
        transform: rotate(-45deg);
    }
    
    .navbarNav > li > a{
        display: block;
        padding: 10px 20px;
        font-size: 18px;
    }    
    
    .topBar {
        display: none;
    }
}

@media (min-width: 768px){
    .navbarToggler,
    .collapseWrap > .hdBtn,
    .navbarTogglerClose{
        display: none;
    }

    .mainHeader{
        flex-direction: row;
    }

    .mainHeader > .hdBtn{
        display: inline-block;
    }

    .navbarNav{
        display: flex;
        align-items: center;
    }

    .hdBtn{
        padding: 23px 30px;
        min-width: 100px;
        margin: 0;
        display: inline-block;
        height: 100%;
    }

    .mainHeader{
        padding: 0;
    }

    .navbarNav > li > a{
        position: relative;
        display: inline-block;
        padding: 20px 10px;
        border-right: 1px solid rgba(242, 242, 242, .23);
    }
    
    .mainLogo {
        max-width: 160px;
    }

    .topBar,
    #pageHeader{
        transition: all .35s ease;
        background-color: var(--themeBlack);
    }

    #pageHeader.positionSticky{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
    }

    #pageHeader.positionSticky .topBar{
        height: 0;
        padding: 0;
        overflow: hidden;
    }
    
    .phStickyWrap{
        min-height: 131px;
    }
}

@media (min-width: 992px){
    .hdBtn{
        padding: 24px 150px 25px 49px;
    }

    .topBar{
        padding: 22px 15px 17px;
    }

    .navbarNav > li > a{
        padding-left: 15px;
        padding-right: 15px;
    }

    .mainLogo{
        max-width: 180px;
    }
    
    .phStickyWrap{
        min-height: 147px;
    }
}

@media (min-width: 1200px){
    .hdBtn{
        padding: 27px 295px 28px 49px;
    }

    .navbarNav{
        padding-left: 50px;
    }

    .navbarNav > li > a{
        padding: 23px 20px;
    }
    
    .phStickyWrap{
        min-height: 153px;
    }
}

@media (min-width: 1400px){
    .navbarNav{
        padding-left: 100px;
    }

    .mainLogo{
        max-width: 210px;
    }
}

@media (min-width: 1600px){
    .navbarNav{
        padding-left: 158px;
    }
}